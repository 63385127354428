import { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import {
  selectconfigDataState,
  useGetPresignedUrlMutation
} from 'redux/modules/config';

import { useAuth } from './useAuth';

export const useAwsS3 = () => {
  const [progress, setProgress] = useState(0);
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const configData = useSelector(selectconfigDataState);
  const [fileUpload] = useGetPresignedUrlMutation();

  const uploadFile = async (file, folder = 'company/documents') => {
    const fileName = `${Date.now()}-${file?.name.replace(/ /g, '')}`;
    setIsLoading(true);
    try {
      const payload = {
        token: user.token,
        data: {
          filePath: `${folder}/${fileName}`,
          bucket: configData?.bucketName
        }
      };
      fileUpload(payload)
        .unwrap()
        .then(async (res) => {
          const presignedUrl = res.result;
          await axios.put(presignedUrl, file, {
            headers: { 'Content-Type': file.type },
            onUploadProgress: (evt) => {
              setProgress(Math.round((evt.loaded / evt.total) * 100));
            }
          });
        })
        .catch((err) => console.log(err));

      setIsLoading(false);
      return `https://${configData?.bucketName}.s3.${configData?.bucketRegion}.amazonaws.com/${folder}/${fileName}`;
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsLoading(false);
      throw error;
    }
  };

  const deleteFile = async (fileUrl) => {
    try {
      const fileName = fileUrl.split('/').pop();
      const response = await axios.delete(
        '/api/v2/config/files/presigned-url',
        { data: { fileName } }
      );
      return response.data;
    } catch (error) {
      console.error('Error deleting file:', error);
      throw error;
    }
  };

  return {
    progress,
    uploadFile,
    isLoading,
    deleteFile
  };
};
