import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getConfigData: builder.query({
      query: () => ({
        url: '/config/get-instance',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
        }
      }),
      transformResponse: (response) => response.result
    }),
    getCountries: builder.query({
      query: (payload) => ({
        url: '/locales/get-countries',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getCountryState: builder.query({
      query: (payload) => ({
        url: `locales/get-country-states?countryCode=${payload.countryCode}`,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getStateCity: builder.query({
      query: (payload) => ({
        url: `/locales/get-cities-of-state?stateCode=${payload.stateCode}&countryCode=${payload.countryCode}`,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getProductsLite: builder.query({
      query: (payload) => ({
        url: `/products/product-list-lite?searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    getCompanyProducts: builder.query({
      query: (payload) => ({
        url: `/company-product/get-company-products?industry=${payload.industry}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result,
      refetchOnMountOrArgChange: true // Set this option to true to trigger a refetch when the "industry" value changes
    }),
    getCompanyLite: builder.query({
      query: (payload) => ({
        url: `/company/company-list-lite?${
          payload.businessType ? `businessType=${payload.businessType}` : ''
        }${payload.productId ? `&productId=${payload.productId}` : ''}${
          payload.companyId ? `&companyId=${payload.companyId}` : ''
        }&searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getAgencyLite: builder.query({
      query: (payload) => ({
        url: `/company/get-agencies-company-list?${
          payload.businessType ? `businessType=${payload.businessType}` : ''
        }${payload.productId ? `&productId=${payload.productId}` : ''}${
          payload.companyId ? `&companyId=${payload.companyId}` : ''
        }`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getIndustries: builder.query({
      query: (payload) => ({
        url: '/products/get-industries',
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result.industries
    }),
    updateCompanyApiProduct: builder.mutation({
      query: (payload) => ({
        url: '/company/update-company-api-platform',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    getPresignedUrl: builder.mutation({
      query: (payload) => ({
        url: '/config/files/upload-url',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    getUserRole: builder.query({
      query: (payload) => ({
        url: `/user/get-user-roles?businessType=${payload.businessType}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    })
  })
});

export const {
  useGetConfigDataQuery,
  useGetCountriesQuery,
  useGetCountryStateQuery,
  useGetProductsLiteQuery,
  useLazyGetProductsLiteQuery,
  useGetCompanyProductsQuery,
  useGetCompanyLiteQuery,
  useLazyGetCompanyLiteQuery,
  useGetUserRoleQuery,
  useUpdateCompanyApiProductMutation,
  useGetIndustriesQuery,
  useGetAgencyLiteQuery,
  useGetPresignedUrlMutation
} = configApi;
